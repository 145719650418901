import { RoutesRFP } from 'config/routes';

const defaultWhiteTheme = {
  theme: 'rfp',
  bgColor: '#000000',
  palette: { main: 'white' },
};

const themes = {
  [RoutesRFP.DefaultArtBuilder.name]: {
    theme: 'rfp/art-builder',
    bgColor: '#000000',
    palette: { main: 'white' },
  },
  'published/Individual': {
    theme: 'rfp',
    bgColor: '#DBD6EB',
    palette: { main: 'black', footer: 'white' },
  },
  'published/Studio': {
    theme: 'rfp',
    bgColor: '#F2DFC2',
    palette: { main: 'black', footer: 'white' },
  },
  'preview/Individual': {
    theme: 'rfp',
    bgColor: '#DBD6EB',
    palette: { main: 'black', footer: 'white' },
  },
  'preview/Studio': {
    theme: 'rfp',
    bgColor: '#F2DFC2',
    palette: { main: 'black', footer: 'white' },
  },
  [RoutesRFP.ProfileCover.name]: {
    theme: 'profileInfo',
    bgColor: '#BBDCB0',
    palette: { main: 'black' },
  },
  [RoutesRFP.ProfileSkills.name]: {
    theme: 'profileInfo',
    bgColor: '#BBDCB0',
    palette: { main: 'black' },
  },
};

export default route => {
  const theme = themes[route];
  return theme || defaultWhiteTheme;
};
