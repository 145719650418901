import { setup } from 'bem-cn';
import cn from 'classnames/bind';

const bemCN = styles => blockName => {
  const cx = cn.bind(styles);
  const b = setup({ mod: '--', modValue: '-' })(blockName);
  return (...params) => cx(b(...params).split(' '));
};

export default bemCN;
