const QueryString = require('qs');

const prefixRFP = 'talent';

const rfpProfileUrl = ({ domain, prefix = '', publishStatus }) => {
  const profileUrl = `${prefix}/${prefixRFP}/p/${domain}`;
  if (publishStatus) {
    const query = QueryString.stringify({ status: publishStatus });
    const urlWithQuery = `${profileUrl}?${query}`;
    return urlWithQuery;
  }
  return profileUrl;
};

module.exports = {
  prefixRFP,
  rfpProfileUrl,
};
