import React from 'react';
import cn from 'classnames/bind';
import PropTypes from 'prop-types';

import Arrow from './Arrow';

import styles from './styles.styl';

const cx = cn.bind(styles);

function Button({
  className,
  theme,
  isOutlined,
  isUppercase,
  children,
  size,
  tag,
  type = 'button',
  ...other
}) {
  const buttonClassNames = cx(
    'Button',
    {
      [`Button--${size}`]: size !== 'md',
      [`Button--uppercase`]: isUppercase,
      [`Button--${theme}`]: theme && !isOutlined,
      [`Button--${theme}-outlined`]: theme && isOutlined,
    },
    className,
  );
  const TagName = tag || 'button';
  return (
    <TagName className={buttonClassNames} type={type} {...other}>
      {theme === 'back' && <Arrow />}
      {children}
    </TagName>
  );
}

Button.defaultProps = {
  size: 'md',
  className: '',
  isOutlined: false,
  isUppercase: true,
};

export const buttonThemePropType = PropTypes.oneOf([
  'white',
  'black',
  'green',
  'back',
  'purple',
  'blue',
  'red',
]);

Button.propTypes = {
  size: PropTypes.string,
  theme: buttonThemePropType,
  children: PropTypes.node,
  isOutlined: PropTypes.bool,
  className: PropTypes.string,
  isUppercase: PropTypes.bool,
  type: PropTypes.string,
};

export default Button;
