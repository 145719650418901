/* eslint-disable */
// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import Heading from 'components/Heading';

import styles from './styles.styl';

const Section = ({ id, className, title, subtitle, portalEl, children }) => (
  <section id={id} className={cn(styles.Page__section, className)}>
    {(title || subtitle) && (
      <div className={styles.Page__sectionHeader}>
        <Heading className={styles.Page__sectionHeading} tagName="h4" level={4}>
          {title && subtitle ? <b>{title}</b> : title}
          {subtitle}
        </Heading>

        {portalEl && <div id={portalEl} className={styles.Page__sectionPortal} />}
      </div>
    )}
    {children}
  </section>
);

Section.defaultProps = {
  className: '',
};

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default Section;
