// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Page from 'components/Page';

import styles from './styles.styl';

const RFPPage = ({ children, meta, className, ...rest }) => {
  const extMeta = {
    description: {
      name: 'description',
      type: 'name',
      content:
        '80 LEVEL Talent is a premium job matching service for digital, creative, and video game companies and studios.',
    },
    ...meta,
  };
  return (
    <Page
      meta={extMeta}
      title="80 Level Talent"
      className={cn(styles.RFPPage, className)}
      {...rest}
    >
      {children}
    </Page>
  );
};

RFPPage.propTypes = {
  children: PropTypes.node,
  meta: PropTypes.object,
  className: PropTypes.string,
};

export default RFPPage;
