import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.styl';

const RFPContainer = React.forwardRef(({ className, ...other }, forwardedRef) => {
  return <div ref={forwardedRef} className={cn(styles.RFPContainer, className)} {...other} />;
});

RFPContainer.displayName = 'RFPContainer';

RFPContainer.defaultProps = {
  className: '',
};

RFPContainer.propTypes = {
  className: PropTypes.string,
  forwardedRef: PropTypes.object,
};

export default RFPContainer;
