import _map from 'lodash/map';

/** Возвращает ссылку из URL и параметров qyery.
 * @param {string} url
 * @param {object} query
 */
export default function(url, query) {
  const queryString =
    Object.keys(query).length > 0 ? _map(query, (value, name) => `${name}=${value}`).join('&') : '';
  const isExistQuery = /\?/.test(url);

  return url + (isExistQuery ? '&' : '?') + queryString;
}
